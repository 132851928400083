export const configschema = {
  type: "object",
  properties: {
    provider: {
      type: "object",
      properties: {
        azurerm: {
          type: "object",
          properties: {
            features: {
              type: "object",
              properties: {},
            },
          },
        },
      },
    },
    terraform: {
      type: "object",
      properties: {
        backend: {
          type: "object",
          properties: {
            azurerm: {
              type: "object",
              properties: {
                storage_account_name: {
                  type: "string",
                  default: "soeprodtfstate",
                },
                container_name: {
                  type: "string",
                  default: "tfstate",
                },
                key: {
                  type: "string",
                },
                resource_group_name: {
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
    locals: {
      title: "",
      type: "object",
      properties: {
        client: {
          title: "Customer Name",
          type: "string",
        },
        ticker: {
          title: "Four Letter Ticker Symbol",
          type: "string",
          minLength: 1,
          maxLength: 4,
        },
        environment: {
          title: "Environment Type",
          type: "string",
          oneOf: [
            {
              const: "dev",
              title: "Dev",
            },
            {
              const: "uat",
              title: "UAT",
            },
            {
              const: "prod",
              title: "Prod",
            },
          ],
        },
        dns_endpoint: {
          title: "Fully Qualified Domain Name (FQDN)",
          type: "string",
        },
        fault_domain_count: {
          type: "integer",
          default: 2,
        },
        tags: {
          type: "object",
          properties: {
            client: {
              type: "string",
              default: "${lower(local.client)}",
            },
            environment: {
              type: "string",
              default: "${local.environment}",
            },
            owner: {
              type: "string",
              default: "sre-team@stackoverflow.com",
            },
            config: {
              type: "string",
              default: "tf-soe-standalone",
            },
          },
        },
        appgateway_backend_request_timeout: {
          type: "integer",
          default: 60,
        },
        ssl_use_wildcard: {
          title: "Private SSL Certificate",
          type: "boolean",
          default: true,
        },
        ssl_cert_data: {
          type: "string",
        },
        ssl_cert_password: {
          type: "string",
        },
        sql_sites_dtus: {
          type: "string",
          enum: ["S0", "S1", "S2", "S3", "S4"],
          default: "S1",
        },
        sql_stack_overflow_dtus: {
          type: "string",
          enum: ["S0", "S1", "S2", "S3", "S4"],
          default: "S1",
        },
        octopus_deploy: {
          type: "boolean",
          default: true,
        },
        teams: {
          type: "boolean",
          default: false,
        },
        sendgrid: {
          type: "boolean",
          default: false,
        },
        use_random_prefix_secrets: {
          type: "boolean",
          default: true,
        },
        override_sql_password_special_flag: {
          type: "boolean",
          default: true,
        },
        primary_region: {
          title: "Primary Azure Region",
          type: "string",
          enum: [
            "East US",
            "West US",
            "West US 2",
            "South Central US",
            "West Central US",
            "Canada Central",
            "Canada East",
            "North Europe",
            "West Europe",
            "UK South",
            "UK West",
            "Australia East",
            "Australia Southeast",
          ],
        },
        primary_address_prefix: {
          title: "Primary Region IP Range",
          type: "string",
          readOnly: true,
        },
        primary_alerts_enabled: {
          type: "boolean",
          default: true,
        },
        hadr: {
          type: "boolean",
          default: false,
        },
        secondary_region: {
          title: "Secondary Azure Region",
          type: "string",
          readOnly: true,
        },
        secondary_address_prefix: {
          title: "Secondary Region IP Range",
          type: "string",
          readOnly: true,
        },
        secondary_alerts_enabled: {
          type: "boolean",
          default: true,
        },
        enable_uptime_monitoring: {
          type: "boolean",
          default: true,
        },
        enable_uptime_monitoring_dns_override: {
          type: "boolean",
          default: false,
        },
        enable_uptime_monitoring_tls_errors: {
          type: "boolean",
          default: false,
        },
        sql_customer_rules: {
          type: "array",
          items: {
            allOf: [
              {
                properties: {
                  name: {
                    type: "string",
                  },
                  start_ip: {
                    type: "string",
                  },
                  end_ip: {
                    type: "string",
                  },
                },
              },
            ],
          },
        },
        appgateway_custom_rules: {
          type: "array",
          items: {
            allOf: [
              {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                  },
                  description: {
                    type: "string",
                  },
                  source_address_prefixes: {
                    type: "array",
                    title: "Source IP Addresses/CIDR Ranges",
                    items: {
                      type: "string",
                    },
                  },
                },
                required: ["name", "description", "source_address_prefixes"],
              },
            ],
          },
        },
        enable_cloudflare: {
          type: "boolean",
          default: false
        },
        enable_aks_only_backend: {
          type: "boolean",
          default: false
        },
      },
    },
    module: {
      type: "object",
      properties: {
        data: {
          type: "object",
          properties: {
            source: {
              type: "string",
              default: "../soe-terraform/submodule/terraform-module-data",
            },
            environment: {
              type: "string",
              default: "${local.environment}",
            },
          },
        },
        soe_standalone: {
          type: "object",
          properties: {
            source: {
              type: "string",
              default: "../soe-terraform/base",
            },
            ticker: {
              type: "string",
            },
            environment: {
              type: "string",
            },
            region: {
              type: "string",
            },
            dns_endpoint: {
              type: "string",
            },
            web_fault_domain_count: {
              type: "integer",
            },
            search_fault_domain_count: {
              type: "integer",
            },
            tags: {
              type: "string",
            },
            network_address_space: {
              type: "string",
            },
            network_main_subnet: {
              type: "string",
            },
            network_appgateway_subnet: {
              type: "string",
            },
            network_gateway_subnet: {
              type: "string",
            },
            appgateway_backend_request_timeout: {
              type: "integer",
            },
            octopus_deploy: {
              type: "boolean",
            },
            octopus_environment_name: {
              type: "string",
            },
            alerts_enabled: {
              type: "boolean",
            },
            sql_sites_dtus: {
              type: "string",
            },
            sql_stack_overflow_dtus: {
              type: "string",
            },
            teams: {
              type: "boolean",
            },
            ssl_use_wildcard: {
              type: "boolean",
            },
            ssl_cert_data: {
              type: "string",
            },
            ssl_cert_password: {
              type: "string",
            },
            use_random_prefix_secrets: {
              type: "boolean",
            },
            override_sql_password_special_flag: {
              type: "boolean",
            },
            appgateway_custom_rules: {
              type: "array",
              default: "${local.appgateway_custom_rules}",
            },
            sql_customer_rules: {
              type: "array",
              default: "${local.sql_customer_rules}",
            },
            rw_object_id: {
              type: "string",
            },
            key_vault_uri: {
              type: "string",
            },
            mgmt_subnet_address: {
              type: "string",
            },
            dns_zone_rsg_name: {
              type: "string",
            },
            sql_internal_dns_zone: {
              type: "string",
            },
            private_dns_zone_resource_group_name: {
              type: "string",
            },
            log_analytics_workspace_id: {
              type: "string",
            },
            mgmt_rsg_name: {
              type: "string",
            },
            private_dns_zone_name: {
              type: "string",
            },
            appgw_dns_zone_name: {
              type: "string",
            },
            mgmt_vnet_id: {
              type: "string",
            },
            mgmt_network_rsg_name: {
              type: "string",
            },
            key_vault_id: {
              type: "string",
            },
            mgmt_vnet_name: {
              type: "string",
            },
            ssl_wildcard_password_secret: {
              type: "string",
            },
            ssl_wildcard_data_secret: {
              type: "string",
            },
            octopus_deploy_uri: {
              type: "string",
            },
            deployify_object_id: {
              type: "string",
            },
            mgmt_bastion_subnets: {
              type: "string",
            },
            enable_uptime_monitoring: {
              type: "boolean",
            },
            enable_uptime_monitoring_dns_override: {
              type: "boolean",
            },
            ignore_uptime_monitoring_tls_errors: {
              type: "boolean",
            },
            enable_cloudflare: {
              type: "boolean",
            },
            enable_aks_only_backend: {
              type: "boolean",
            }
          },
        },
        soe_sec: {
          type: "object",
          properties: {
            source: {
              type: "string",
              default: "../soe-terraform/base",
            },
            ticker: {
              type: "string",
              default: "${lower(local.ticker)}",
            },
            environment: {
              type: "string",
              default: "${lower(local.environment)}",
            },
            region: {
              type: "string",
              default: "${local.secondary_region}",
            },
            dns_endpoint: {
              type: "string",
              default: "${local.dns_endpoint}",
            },
            web_fault_domain_count: {
              type: "string",
              default: "${local.fault_domain_count}",
            },
            search_fault_domain_count: {
              type: "string",
              default: "${local.fault_domain_count}",
            },
            tags: {
              type: "string",
              default: "${local.tags}",
            },
            hadr_secondary: {
              type: "string",
              default: "${local.hadr}",
            },
            network_address_space: {
              type: "string",
              default: "${local.secondary_address_prefix}0/24",
            },
            network_main_subnet: {
              type: "string",
              default: "${local.secondary_address_prefix}0/25",
            },
            network_appgateway_subnet: {
              type: "string",
              default: "${local.secondary_address_prefix}128/29",
            },
            network_gateway_subnet: {
              type: "string",
              default: "${local.secondary_address_prefix}144/28",
            },
            appgateway_backend_request_timeout: {
              type: "string",
              default: "${local.appgateway_backend_request_timeout}",
            },
            octopus_deploy: {
              type: "string",
              default: "${local.octopus_deploy}",
            },
            octopus_environment_name: {
              type: "string",
              default: "${local.environment}",
            },
            alerts_enabled: {
              type: "string",
              default: "${local.primary_alerts_enabled}",
            },
            sql_sites_dtus: {
              type: "string",
              default: "${local.sql_sites_dtus}",
            },
            sql_stack_overflow_dtus: {
              type: "string",
              default: "${local.sql_stack_overflow_dtus}",
            },
            teams: {
              type: "string",
              default: "${local.teams}",
            },
            ssl_use_wildcard: {
              type: "string",
              default: "${local.ssl_use_wildcard}",
            },
            ssl_cert_data: {
              type: "string",
              default: "${local.ssl_cert_data}",
            },
            ssl_cert_password: {
              type: "string",
              default: "${local.ssl_cert_password}",
            },
            use_random_prefix_secrets: {
              type: "string",
              default: "${local.use_random_prefix_secrets}",
            },
            override_sql_password_special_flag: {
              type: "string",
              default: "${local.override_sql_password_special_flag}",
            },
            hadr_prefix_pri: {
              type: "string",
              default: "${module.soe_standalone.prefix}",
            },
            hadr_rsgname_pri: {
              type: "string",
              default: "${module.soe_standalone.resource_group_name}",
            },
            hadr_sql_server_id: {
              type: "string",
              default: "${module.soe_standalone.sql_server_id}",
            },
            hadr_sql_sites_id: {
              type: "string",
              default: "${module.soe_standalone.sql_sites_id}",
            },
            hadr_sql_stack_id: {
              type: "string",
              default: "${module.soe_standalone.sql_stack_overflow_id}",
            },
            hadr_sql_scheduler_id: {
              type: "string",
              default: "${module.soe_standalone.sql_scheduler_id}",
            },
            hadr_sql_exception_id: {
              type: "string",
              default: "${module.soe_standalone.sql_exception_id}",
            },
            hadr_appgw_public_ip_id: {
              type: "string",
              default: "${module.soe_standalone.appgw_public_ip_id}",
            },
            sql_password: {
              type: "string",
              default: "${module.soe_standalone.sql_password}",
            },
            sql_apppool_password: {
              type: "string",
              default: "${module.soe_standalone.sql_apppool_password}",
            },
            sql_migrations_password: {
              type: "string",
              default: "${module.soe_standalone.sql_migrations_password}",
            },
            appgateway_custom_rules: {
              type: "array",
              default: "${local.appgateway_custom_rules}",
            },
            sql_customer_rules: {
              type: "array",
              default: "${local.sql_customer_rules}",
            },
            enable_cloudflare: {
              type: "string",
              default: "${local.enable_cloudflare}",
            },
            enable_aks_only_backend: {
              type: "string",
              default: "${local.enable_aks_only_backend}",
            },
          },
        },
        hadr: {
          type: "object",
          properties: {
            source: {
              type: "string",
              default: "../soe-terraform/submodule/terraform-module-hadr",
            },
            primary_prefix: {
              type: "string",
              default: "${module.soe_standalone.prefix}",
            },
            primary_location: {
              type: "string",
              default: "${local.primary_region}",
            },
            integrations_gw_pubip_sec: {
              type: "string",
              default: "${module.soe_sec.appgw_public_ip_id}",
            },
            ticker: {
              type: "string",
              default: "${local.ticker}",
            },
            integrations_hostname: {
              type: "string",
              default: "${module.data.integrations_endpoint_prefix}",
            },
            tags: {
              type: "string",
              default: "${local.tags}",
            },
            enable_integrations_secondary: {
              type: "boolean",
              default: true,
            },
            log_analytics_workspace_id: {
              type: "string",
              default:
                "/subscriptions/ecbe2cff-3a89-45ef-8eeb-056dd853e0a4/resourceGroups/sei-mgmt/providers/Microsoft.OperationalInsights/workspaces/SEI-MGMT-OMS",
            },
            secondary_prefix: {
              type: "string",
              default: "${module.soe_sec.prefix}",
            },
            enable_integrations_primary: {
              type: "boolean",
              default: true,
            },
            integrations_gw_pubip_pri: {
              type: "string",
              default: "${module.soe_standalone.appgw_public_ip_id}",
            },
            primary_resource_group_name: {
              type: "string",
              default: "${module.soe_standalone.resource_group_name}",
            },
            secondary_resource_group_name: {
              type: "string",
              default: "${module.soe_sec.resource_group_name}",
            },
            integrations_dns_endpoint: {
              type: "string",
              default:
                "${module.data.integrations_endpoint_prefix}.stackenterprise.co",
            },
            enable_hadr_primary_private_link_for_secondary_sql: {
              type: "boolean",
              default: true,
            },
            enable_hadr_primary_vnet_to_secondary_vnet: {
              type: "boolean",
              default: true,
            },
            secondary_location: {
              type: "string",
              default: "${local.secondary_region}",
            },
            secondary_vnet_id: {
              type: "string",
              default: "${module.soe_sec.vnet_id}",
            },
            secondary_vnet_name: {
              type: "string",
              default: "${module.soe_sec.vnet_name}",
            },
            secondary_vnet_address_space: {
              type: "string",
              default: "${local.secondary_address_prefix}0/24",
            },
            secondary_subnet_id: {
              type: "string",
              default: "${module.soe_sec.main_subnet_id}",
            },
            secondary_nsg_name: {
              type: "string",
              default: "${module.soe_sec.nsg_name}",
            },
            secondary_nsg_search_name: {
              type: "string",
              default: "${module.soe_sec.nsg_search_name}",
            },
            secondary_sql_server_id: {
              type: "string",
              default: "${module.soe_sec.sql_server_id}",
            },
            secondary_sql_private_link_private_ip_address: {
              type: "string",
              default:
                "${module.soe_sec.sql_private_endpoint_private_ip_address}",
            },
            enable_hadr_secondary_private_link_for_primary_sql: {
              type: "boolean",
              default: true,
            },
            enable_hadr_secondary_vnet_to_primary_vnet: {
              type: "boolean",
              default: true,
            },
            primary_vnet_id: {
              type: "string",
              default: "${module.soe_standalone.vnet_id}",
            },
            primary_vnet_name: {
              type: "string",
              default: "${module.soe_standalone.vnet_name}",
            },
            primary_vnet_address_space: {
              type: "string",
              default: "${local.primary_address_prefix}0/24",
            },
            primary_subnet_id: {
              type: "string",
              default: "${module.soe_standalone.main_subnet_id}",
            },
            primary_nsg_name: {
              type: "string",
              default: "${module.soe_standalone.nsg_name}",
            },
            primary_nsg_search_name: {
              type: "string",
              default: "${module.soe_standalone.nsg_search_name}",
            },
            primary_sql_server_id: {
              type: "string",
              default: "${module.soe_standalone.sql_server_id}",
            },
            primary_sql_private_link_private_ip_address: {
              type: "string",
              default:
                "${module.soe_standalone.sql_private_endpoint_private_ip_address}",
            },
          },
        },
      },
    },
    data: {
      type: "object",
      properties: {
        azurerm_key_vault_secret: {
          type: "object",
          properties: {
            "cert-data": {
              type: "object",
              properties: {
                name: {
                  type: "string",
                },
                key_vault_id: {
                  type: "string",
                },
              },
            },
            "cert-pass": {
              type: "object",
              properties: {
                name: {
                  type: "string",
                },
                key_vault_id: {
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
}

export const configjson = {
  provider: { azurerm: { features: {} } },
  terraform: {
    backend: {
      azurerm: {
        storage_account_name: "soeprodtfstate",
        container_name: "tfstate",
        key: "",
        resource_group_name: "",
      },
    },
  },
  locals: {
    client: "",
    ticker: "",
    environment: "",
    dns_endpoint: "",
    fault_domain_count: 2,
    tags: {
      client: "${lower(local.client)}",
      environment: "${local.environment}",
      owner: "sre-team@stackoverflow.com",
      config: "tf-soe-standalone",
    },
    appgateway_backend_request_timeout: 60,
    ssl_use_wildcard: true,
    ssl_cert_data: "",
    ssl_cert_password: "",
    sql_sites_dtus: "S1",
    sql_stack_overflow_dtus: "S1",
    octopus_deploy: true,
    teams: false,
    sendgrid: false,
    use_random_prefix_secrets: true,
    override_sql_password_special_flag: true,
    primary_region: "",
    primary_address_prefix: "",
    primary_alerts_enabled: true,
    hadr: false,
    secondary_region: "",
    secondary_address_prefix: "",
    secondary_alerts_enabled: true,
    sql_customer_rules: [],
    appgateway_custom_rules: [],
    enable_uptime_monitoring: true,
    enable_uptime_monitoring_dns_override: true,
    ignore_uptime_monitoring_tls_errors: true,
    enable_cloudflare: false,
    enable_aks_only_backend: false
  },
  module: {
    data: {
      source: "../soe-terraform/submodule/terraform-module-data",
      environment: "${local.environment}",
    },
    soe_standalone: {
      source: "../soe-terraform/base",
      ticker: "${lower(local.ticker)}",
      environment: "${local.environment}",
      region: "${local.primary_region}",
      dns_endpoint: "${local.dns_endpoint}",
      web_fault_domain_count: "${local.fault_domain_count}",
      search_fault_domain_count: "${local.fault_domain_count}",
      tags: "${local.tags}",
      network_address_space: "${local.primary_address_prefix}0/24",
      network_main_subnet: "${local.primary_address_prefix}0/25",
      network_appgateway_subnet: "${local.primary_address_prefix}128/29",
      network_gateway_subnet: "${local.primary_address_prefix}144/28",
      appgateway_backend_request_timeout:
        "${local.appgateway_backend_request_timeout}",
      octopus_deploy: "${local.octopus_deploy}",
      octopus_environment_name: "${local.environment}",
      alerts_enabled: "${local.primary_alerts_enabled}",
      sql_sites_dtus: "${local.sql_sites_dtus}",
      sql_stack_overflow_dtus: "${local.sql_stack_overflow_dtus}",
      teams: "${local.teams}",
      ssl_use_wildcard: "${local.ssl_use_wildcard}",
      use_random_prefix_secrets: "${local.use_random_prefix_secrets}",
      override_sql_password_special_flag:
        "${local.override_sql_password_special_flag}",
      hadr_primary: "${local.hadr}",
      ssl_cert_data: "${local.ssl_cert_data}",
      ssl_cert_password: "${local.ssl_cert_password}",
      appgateway_custom_rules:
        "${length(local.appgateway_custom_rules) != 0 ? local.appgateway_custom_rules: null}",
      sql_customer_rules:
        "${length(local.sql_customer_rules) != 0 ? local.sql_customer_rules : null}",
      rw_object_id: "${module.data.rw_object_id}",
      key_vault_uri: "${module.data.key_vault_uri}",
      mgmt_subnet_address: "${module.data.mgmt_subnet_address}",
      dns_zone_rsg_name: "${module.data.mgmt_rsg_name}",
      sql_internal_dns_zone: "${module.data.private_dns_zone_name}",
      private_dns_zone_resource_group_name: "${module.data.mgmt_rsg_name}",
      log_analytics_workspace_id: "${module.data.log_analytics_workspace_id}",
      mgmt_rsg_name: "${module.data.mgmt_rsg_name}",
      private_dns_zone_name: "${module.data.private_dns_zone_name}",
      appgw_dns_zone_name: "${module.data.appgw_dns_zone_name}",
      mgmt_vnet_id: "${module.data.mgmt_vnet_id}",
      mgmt_network_rsg_name: "${module.data.mgmt_rsg_name}",
      key_vault_id: "${module.data.key_vault_id}",
      mgmt_vnet_name: "${module.data.mgmt_vnet_name}",
      ssl_wildcard_password_secret:
        "${module.data.ssl_wildcard_password_secret}",
      ssl_wildcard_data_secret: "${module.data.ssl_wildcard_data_secret}",
      octopus_deploy_uri: "${module.data.octopus_deploy_uri}",
      deployify_object_id: "${module.data.deployify_object_id}",
      mgmt_bastion_subnets: "${module.data.mgmt_bastion_subnets}",
      enable_uptime_monitoring: "${local.enable_uptime_monitoring}",
      enable_uptime_monitoring_dns_override: "${local.enable_uptime_monitoring_dns_override}",
      ignore_uptime_monitoring_tls_errors: "${local.ignore_uptime_monitoring_tls_errors}",
      enable_cloudflare: "${local.enable_cloudflare}",
      enable_aks_only_backend: "${local.enable_aks_only_backend}",
    },
  },
}

export const hadrjson = {
  soe_sec: {
    source: "../soe-terraform/base",
    ticker: "${lower(local.ticker)}",
    environment: "${lower(local.environment)}",
    region: "${local.secondary_region}",
    dns_endpoint: "${local.dns_endpoint}",
    web_fault_domain_count: "${local.fault_domain_count}",
    search_fault_domain_count: "${local.fault_domain_count}",
    tags: "${local.tags}",
    hadr_secondary: "${local.hadr}",
    network_address_space: "${local.secondary_address_prefix}0/24",
    network_main_subnet: "${local.secondary_address_prefix}0/25",
    network_appgateway_subnet: "${local.secondary_address_prefix}128/29",
    network_gateway_subnet: "${local.secondary_address_prefix}144/28",
    appgateway_backend_request_timeout: "${local.appgateway_backend_request_timeout}",
    octopus_deploy: "${local.octopus_deploy}",
    octopus_environment_name: "${local.environment}",
    alerts_enabled: "${local.primary_alerts_enabled}",
    sql_sites_dtus: "${local.sql_sites_dtus}",
    sql_stack_overflow_dtus: "${local.sql_stack_overflow_dtus}",
    teams: "${local.teams}",
    ssl_use_wildcard: "${local.ssl_use_wildcard}",
    ssl_cert_data: "${local.ssl_cert_data}",
    ssl_cert_password: "${local.ssl_cert_password}",
    use_random_prefix_secrets: "${local.use_random_prefix_secrets}",
    override_sql_password_special_flag: "${local.override_sql_password_special_flag}",
    hadr_prefix_pri: "${module.soe_standalone.prefix}",
    hadr_rsgname_pri: "${module.soe_standalone.resource_group_name}",
    hadr_sql_server_id: "${module.soe_standalone.sql_server_id}",
    hadr_sql_sites_id: "${module.soe_standalone.sql_sites_id}",
    hadr_sql_stack_id: "${module.soe_standalone.sql_stack_overflow_id}",
    hadr_sql_scheduler_id: "${module.soe_standalone.sql_scheduler_id}",
    hadr_sql_exception_id: "${module.soe_standalone.sql_exception_id}",
    hadr_appgw_public_ip_id: "${module.soe_standalone.appgw_public_ip_id}",
    sql_password: "${module.soe_standalone.sql_password}",
    sql_apppool_password: "${module.soe_standalone.sql_apppool_password}",
    sql_migrations_password: "${module.soe_standalone.sql_migrations_password}",
    appgateway_custom_rules: "${length(local.appgateway_custom_rules) != 0 ? local.appgateway_custom_rules: null}",
    sql_customer_rules: "${length(local.sql_customer_rules) != 0 ? local.sql_customer_rules : null}",
    enable_cloudflare: "${local.enable_cloudflare}",
    enable_aks_only_backend: "${local.enable_aks_only_backend}",
  },
  hadr: {
    source: "../soe-terraform/submodule/terraform-module-hadr",
    primary_prefix: "${module.soe_standalone.prefix}",
    primary_location: "${local.primary_region}",
    integrations_gw_pubip_sec: "${module.soe_sec.appgw_public_ip_id}",
    ticker: "${local.ticker}",
    integrations_hostname: "${module.data.integrations_endpoint_prefix}",
    tags: "${local.tags}",
    enable_integrations_secondary: true,
    log_analytics_workspace_id: "/subscriptions/ecbe2cff-3a89-45ef-8eeb-056dd853e0a4/resourceGroups/sei-mgmt/providers/Microsoft.OperationalInsights/workspaces/SEI-MGMT-OMS",
    secondary_prefix: "${module.soe_sec.prefix}",
    enable_integrations_primary: true,
    integrations_gw_pubip_pri: "${module.soe_standalone.appgw_public_ip_id}",
    primary_resource_group_name: "${module.soe_standalone.resource_group_name}",
    secondary_resource_group_name: "${module.soe_sec.resource_group_name}",
    integrations_dns_endpoint: "${module.data.integrations_endpoint_prefix}.stackenterprise.co",
    enable_hadr_primary_private_link_for_secondary_sql: true,
    enable_hadr_primary_vnet_to_secondary_vnet: true,
    secondary_location: "${local.secondary_region}",
    secondary_vnet_id: "${module.soe_sec.vnet_id}",
    secondary_vnet_name: "${module.soe_sec.vnet_name}",
    secondary_vnet_address_space: "${local.secondary_address_prefix}0/24",
    secondary_subnet_id: "${module.soe_sec.main_subnet_id}",
    secondary_nsg_name: "${module.soe_sec.nsg_name}",
    secondary_nsg_search_name: "${module.soe_sec.nsg_search_name}",
    secondary_sql_server_id: "${module.soe_sec.sql_server_id}",
    secondary_sql_private_link_private_ip_address: "${module.soe_sec.sql_private_endpoint_private_ip_address}",
    enable_hadr_secondary_private_link_for_primary_sql: true,
    enable_hadr_secondary_vnet_to_primary_vnet: true,
    primary_vnet_id: "${module.soe_standalone.vnet_id}",
    primary_vnet_name: "${module.soe_standalone.vnet_name}",
    primary_vnet_address_space: "${local.primary_address_prefix}0/24",
    primary_subnet_id: "${module.soe_standalone.main_subnet_id}",
    primary_nsg_name: "${module.soe_standalone.nsg_name}",
    primary_nsg_search_name: "${module.soe_standalone.nsg_search_name}",
    primary_sql_server_id: "${module.soe_standalone.sql_server_id}",
    primary_sql_private_link_private_ip_address: "${module.soe_standalone.sql_private_endpoint_private_ip_address}",
  },
}
